import { RouteRecordRaw } from "vue-router";
import { Route } from "../ts-type/routes";
//公共路由
export const staticRoutes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login.vue"),
  },
  {
    path: "/not-page",
    component: () => import("@/views/notPage/not-page.vue"),
  },
];
//静态路由
export const asyncRoutes: Array<Route> = [
  {
    path: "/home",
    name: "home",
    meta: {
      title: "首页",
      show:true,
      icon: "worker",
    },
    component: () => import("@/layout/index.vue"),
    children: [
      {
        path: "/home/homeindex",
        name: "homeindex",
        meta: {
          title: "工作台",
          icon: "",
          show:true,
        },
        component: () =>
          import("@/views/home/index.vue"),
      },
    ],
  },
  
  // {
  //   path: "/rightsManagement",
  //   name: "rightsManagement",
  //   meta: {
  //     title: "系统管理",
  //     show:true,
  //     icon: "permissions",
  //   },
  //   component: () => import("@/layout/index.vue"),
  //   children: [
  //     {
  //       path: "/rightsManagement/userManagement",
  //       name: "userManagement",
  //       meta: {
  //         title: "用户管理（前台）",
  //         show:true,
  //         icon: "",
  //       },
  //       component: () =>
  //         import("@/views/rightsManagement/userManagement/index.vue"),
  //     },
  //     {
  //       path: "/rightsManagement/userManagementBackstage",
  //       name: "userManagementBackstage",
  //       meta: {
  //         title: "用户管理（后台）",
  //         show:true,
  //         icon: "",
  //       },
  //       component: () =>
  //         import("@/views/rightsManagement/userManagementBackstage/index.vue"),
  //     },
  //     {
  //       path: "/rightsManagement/roleManagement",
  //       name: "roleManagement",
  //       meta: {
  //         title: "角色管理",
  //         show:true,
  //         icon: "",
  //       },
  //       component: () =>
  //         import("@/views/rightsManagement/roleManagement/index.vue"),
  //     },
  //     {
  //       path: "/rightsManagement/menuManagement",
  //       name: "menuManagement",
  //       meta: {
  //         title: "菜单管理",
  //         show:true,
  //         icon: "",
  //       },
  //       component: () =>
  //         import("@/views/rightsManagement/menuManagement/index.vue"),
  //     },
  //   ],
  // },
];


const routes: Array<any> = [...staticRoutes, ...asyncRoutes];

export default routes;
